<template>
<div class="case-content">
  <div class="case-title">新增区域负责人</div>
  <el-form style="display: flex;flex-direction: column;flex: 1;height: 1%;margin-top: 60px" :model="areaForm" :rules="areaRules" ref="areaForm" label-width="90px">
    <el-form-item prop="name" label="姓名">
      <el-input v-model="areaForm.name" style="width: 478px;" placeholder="请输入姓名"></el-input>
    </el-form-item>
    <el-form-item prop="area" label="负责区域">
      <el-cascader v-model="areaForm.area" :props="{children:'city',label:'name',value:'name'}" style="width: 478px;" :options="areaOptions" :show-all-levels="false"></el-cascader>
    </el-form-item>
    <el-form-item prop="phone" label="联系电话">
      <el-input v-model="areaForm.phone" style="width: 478px;" placeholder="请输入正确的联系电话"></el-input>
    </el-form-item>
  </el-form>
  <div class="footer-btn">
    <el-button @click="goBack">返回</el-button>
    <el-button @click="resetBtn">重置</el-button>
    <el-button class="btn-green" @click="submitBtn('areaForm')">提交</el-button>
  </div>
</div>
</template>

<script>
import {areaManagerAdd, areaManagerGet, areaManagerEdit} from '@/utils/apis'
import {areaList} from '@/utils/area'
export default {
  name: "AreaManagerAdd",
  data(){
    var isMobileNumber= (rule, value, callback) => {
      if (!value) {
        return new Error("请输入电话号码");
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        const isPhone = reg.test(value);
        value = Number(value); //转换为数字
        if (typeof value === "number" && !isNaN(value)) {//判断是否为数字
          value = value.toString(); //转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) { //判断是否为11位手机号
            callback(new Error("手机号码格式如:138xxxx8754"));
          } else {
            callback();
          }
        } else {
          callback(new Error("请输入电话号码"));
        }
      }
    };
    return {
      areaForm:{
        id:'',
        name:'',
        area:'',
        phone:'',
      },
      areaOptions:[],
      areaRules:{
        name:[
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字', trigger: 'blur' }
        ],
        area:[
          { required: true, message: '请选择负责区域', trigger: 'change' }
        ],
        phone:[
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { validator:isMobileNumber,trigger: 'blur' }
        ]
      },
      area_manager_id:this.$route.query.id || '',
    }
  },
  mounted() {
    this.areaOptions = areaList;
    this.getEdieArea();
  },
  methods:{
    getEdieArea(){
      if(this.area_manager_id){
        let params = {
          area_manager_id:this.area_manager_id
        }
        areaManagerGet({params}).then((res)=>{
          this.areaForm.name = res.data.name;
          this.areaForm.phone = res.data.phone;
          if(res.data.area_json){
            this.areaForm.area = JSON.parse(res.data.area_json)
          }
        }).catch((err)=>{
          console.log('err', err)
        })
      }
    },
    goBack(){
      this.$router.go(-1)
    },
    resetBtn(){
      this.areaForm.area = '';
      this.$refs.areaForm.resetFields();
    },
    submitBtn(formName){
      this.$refs[formName].validate((valid)=>{
        if(valid){
          let area = this.areaForm.area[this.areaForm.area.length - 1];
          let area_json = JSON.stringify(this.areaForm.area);
          let formData = new FormData();
          formData.append('name',this.areaForm.name);
          formData.append('area',area);
          formData.append('phone',this.areaForm.phone);
          formData.append('area_json', area_json);
          if(this.area_manager_id){
            formData.append('area_manager_id', this.area_manager_id);
            areaManagerEdit(formData).then((res)=>{
              this.$message({
                message:res.msg,
                type:'success',
                duration:1000,
                onClose:()=>{
                  this.$router.push('/areaManager/index')
                }
              })
            }).catch((err)=>{
              console.log('err', err)
            })
          } else {
            areaManagerAdd(formData).then((res)=>{
              this.$message({
                message:res.msg,
                type:'success',
                duration:1000,
                onClose:()=>{
                  this.$router.push('/areaManager/index')
                }
              })
            }).catch((err)=>{
              console.log('err', err)
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.case-content {
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;

  .case-title {
    border-bottom: 1px solid #dcdcdc;
    padding: 30px 0;
    box-sizing: border-box;
    font-size: 16px;
    color: #222;
  }
  .footer-btn{
    text-align: center;
    padding-bottom: 40px;
    .el-button{
      padding: 12px 30px;
      font-size: 14px;
    }
  }
}
</style>